body {
  margin: 0;
  font-family: "Poppins", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 10%;
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 10%;
  background-color: #ffffff6f;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
}

.bg {
  /* The image used */
  background-image: url("./assets/images/Bg-Sublime.jpg");

  /* Full height */
  height: 100vh;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.response {
  align-items: center;
  background-color: #ffffff6f !important;
  color: #181e38 !important;
  font-family: "Poppins" !important;
  font-weight: bold !important;
  width: 242px;
  height: 75px;
  border-radius: 10px;
  text-transform: "none" !important;
}

.quest {
  font-family: "Poppins" !important;
  font-weight: bold !important;
  color: #181e38 !important;
  font-size: 16px !important;
}

.stores {
  align-self: center !important;
  align-items: center !important;
  background-color: #ffffff6f !important;
  color: #181e38 !important;
  font-family: "Poppins" !important;
  font-weight: bold !important;
  width: 160px;
  height: 60px;
  border-radius: 10px;
  text-transform: "none" !important;
}

.title-success {
  font-family: "Poppins" !important;
  font-weight: bold !important;
  color: #181e38 !important;
  font-size: 22px !important;
  margin-top: 20px !important;
}

.subtitle-success {
  font-family: "Poppins" !important;
  color: #181e38 !important;
  opacity: 0.5 !important;
  font-size: 14px !important;
  margin-top: 10px !important;
  text-align: center !important;
}

.error {
  font-family: "Poppins" !important;
  color: #ff5f5f !important;
  font-size: 12px !important;
  margin-top: 10px !important;
}

.title-bottom {
  font-family: "Poppins" !important;
  font-weight: bold !important;
  color: #181e38 !important;
  opacity: 0.8 !important;
  font-size: 14px !important;
  margin-top: 30px !important;
}

.containerform {
  display: flex;
  flex-direction: column;
  align-items: center;
}
